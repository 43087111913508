import {
  PET_RESET,
  PET_SET_ID,
  PET_SET_NAME,
  PET_SET_BIRTHDATE,
  PET_SET_GENDER_ID,
  PET_SET_SPECIE_ID,
  PET_SET_BREED_ID,
  PET_SET_SPECIES,
  PET_SET_BREEDS,
  PET_SET_PETS,
  PET_SET_USER_ID,
  PET_SET_FLIPPED,
  PET_SET_SELECTED
} from '../constants/actions';

const INITIAL_PET = {
  id: 0,
  petName: '',
  petAge: '',
  petGender: '',
  SpecieId: '',
  BreedId: '',
  petStatus: 'A',
  petBirthDate: Date.now(),
  UserId: 0
}

const INITAL_STATE = {
  pet: {...INITIAL_PET},
  error: {
    errorField: null,
    errorMessage: ''
  },
  pets: [],
  species: [],
  breeds: [],
  newPetFlipped: false,
  petsListFlipped: true,
  petSelected: null
};

export default function overlay(state = {...INITAL_STATE}, action) {
  let _pet = {...state.pet};
  switch (action.type) {
  case PET_RESET:
    return {...state, pet: {...INITIAL_PET}};
  case PET_SET_SELECTED:
    return {...state, petSelected: {...action.value}};
  case PET_SET_ID:
    _pet.id = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_NAME:
    _pet.petName = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_BIRTHDATE:
    _pet.petBirthDate = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_GENDER_ID:
    _pet.petGender = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_SPECIE_ID:
    _pet.SpecieId = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_BREED_ID:
    _pet.BreedId = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_SPECIES:
    return {...state, species: [...action.values]};
  case PET_SET_BREEDS:
    return {...state, breeds: [...action.values]};
  case PET_SET_PETS:
    return {...state, pets: [...action.values]};
  case PET_SET_USER_ID:
    _pet.UserId = action.value;
    return {...state, pet: {..._pet}};
  case PET_SET_FLIPPED:
    return {
      ...state,
      pet: {...INITIAL_PET},
      petSelected: null,
      newPetFlipped: action.value.form === 'newPet' ? action.value.flipped : !action.value.flipped,
      petsListFlipped: action.value.form === 'petsList' ? action.value.flipped : !action.value.flipped
    };
  default:
    return state;
  }
}
