import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import moment from 'moment';
import async from 'async';

export function setDate(value) {
  const _unixDate = moment.utc(moment(value).format('YYYY-MM-DD 00:00:00')).unix();
  return { type: types.DAY_BOOKING_SET_DATE, value: _unixDate };
}

export function getDataByDate(value) {
  return (dispatch, getState) => {
    dispatch(setDate(value));
    dispatch(getBookings());
  };
}

export function validateSelection(history, nextRoute) {
  return function (dispatch, getState) {
    if (getState().dayBooking.bookingSelected) {
      history.push(nextRoute);
    } else {
      dispatch(MessageActions.show('Tenes que seleccionar un turno para continuar'));
    }
  }
}

export function setActionDialogOpen(value) {
  return { type: types.DAY_BOOKING_SET_ACTION_DIALOG_OPEN, value };
}

export function setTakeDialogOpen(value) {
  return { type: types.DAY_BOOKING_SET_TAKE_DIALOG_OPEN, value};
}

export function setTimeRange(start, end) {
  return { type: types.DAY_BOOKING_SET_DATE, start, end };
}

export function setBookingList(bookings) {
  return { type: types.DAY_BOOKING_SET_BOOKING_LIST, values: bookings };
}

export function setUserList(users) {
  return { type: types.DAY_BOOKING_SET_USER_LIST, values: users };
}

export function selectBooking(item) {
  return { type: types.DAY_BOOKING_SET_SELECTED, value: item };
}

export function setSelectedUser(value) {
  return { type: types.DAY_BOOKING_SET_SELECTED_USER, value };
}

export function setSelectedPet(value) {
  return { type: types.DAY_BOOKING_SET_SELECTED_PET, value };
}

const getBookings = () => {
  let _uri = config.server;

  return function (dispatch, getState) {
    let _results={};

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getBookings = function(data, cb) {
      fetch(`${_uri}/api/bookings/all/${getState().dayBooking.ServiceId}/${getState().dayBooking.LocationId}/${getState().dayBooking.OperatorUserId}/${getState().dayBooking.bookingDate}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              _results.bookings=data;
              cb(null,data);
            })
          } else {
            _results.bookings=[];
            cb(null,[]);
          }
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getBookings
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(setBookingList(_results.bookings));
        }
    });
  }
}

export function getInitialData() {
  let _uri = config.server;

  return function (dispatch, getState) {
    let _results={};

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getUsers = function(data, cb) {
      fetch(`${_uri}/api/users/pets`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          if (response.status !== 204) {
            response.json()
            .then(data => {
              _results.users=data;
              cb(null,data);
            })
          } else {
            _results.users=[];
            cb(null,[]);
          }
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getBookings = function(data, cb) {
      fetch(`${_uri}/api/bookings/all/${getState().booking.ServiceId}/${getState().booking.LocationId}/${getState().booking.OperatorUserId}/${getState().booking.bookingDate}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              _results.bookings=data;
              cb(null,data);
            })
          } else {
            _results.bookings=[];
            cb(null,[]);
          }
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getUsers,
      getBookings
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(setUserList(_results.users));
          dispatch(setBookingList(_results.bookings));
        }
    });
  }
}

export function createBooking(history, nextRoute = '') {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const validateBooking = (data, cbValidate) => {
      const _user = getState().dayBooking.userSelected;
      const _pet = getState().dayBooking.petSelected;

      if (!_user) return cbValidate(new Error('Es necesario seleccionar al vecino para registrar un turno'));
      if (!_pet) return cbValidate(new Error('Es necesario seleccionar a la mascota para registrar un turno'));
      return cbValidate(null,null);
    }

    const saveBooking = function(data, cbSave) {
      const _booking = getState().dayBooking.bookingSelected;
      const _booker = getState().dayBooking.userSelected;
      const _operatorId = getState().login.userId;
      const _pet = getState().dayBooking.petSelected;

      fetch(`${_uri}/api/booking`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            bookingStartDate: _booking.bookingStartDate,
            bookingEndDate: _booking.bookingEndDate,
            bookingComment: '',
            bookingStatus: 'A',
            ServiceId: _booking.ServiceId,
            BookerUserId: _booker.id,
            OperatorUserId: _operatorId,
            LocationId: _booking.LocationId,
            PetId: _pet.id
          })
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbSave(new Error(data.message));
            })
          } else {
            cbSave(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              cbSave(null,data);
            })
          } else {
            cbSave(null,null);
          }
        }
      })
      .catch(err => {
        cbSave(err);
      });
    };

    async.waterfall([
      start,
      validateBooking,
      saveBooking
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(setSelectedUser(null));
          dispatch(setSelectedPet(null));
          dispatch(setTakeDialogOpen(false));
          dispatch(MessageActions.show('Felicitaciones! Se registró el turno solicitado'));
          dispatch(getBookings());
        }
    });
  }
}

const updateBooking = (booking) => {
  let _uri = config.server;
  return new Promise( function (resolve, reject) {
    fetch(`${_uri}/api/bookings/${booking.id}`,
      {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ` + localStorage.getItem('token'),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(booking)
      }
    )
    .then(function(response) {
      if (!response.ok) {
        if (response.status < 500) {
          response.json()
          .then(data => {
            reject(new Error(data.message));
          })
        } else {
          reject(new Error(response.statusText));
        }
      } else {
        resolve(response);
      }
    })
    .catch((err) => reject(err));
  });
}

export function bookingUpdateStatus(status) {
  return function (dispatch, getState) {
    let _booking = {...getState().dayBooking.bookingSelected, bookingStatus: status};

    console.log(_booking);
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,_booking);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const update = function(data, cbBookings) {
      updateBooking(data)
      .then(function(response) {
        cbBookings(null,null);
      })
      .catch(err => {
        cbBookings(err);
      });
    };

    async.waterfall([
      start,
      update
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (getState().dayBooking.actionDialogOpen) dispatch(setActionDialogOpen(false));
          dispatch(getBookings());
        }
    });
  }
}
