import { BOOKINGS_LIST_SET_HEIGHT, BOOKINGS_LIST_SET_LIST, BOOKINGS_LIST_SET_SELECTED_BOOKING, BOOKINGS_LIST_SET_CANCEL_DIALOG_OPEN, BOOKINGS_LIST_UPDATE_ITEM } from '../constants/actions';

const INITIAL_STATE = {
  height:0,
  bookings:[],
  selectedBooking:null,
  cancelDialogOpen: false
}

export default function bookingsList(state = {...INITIAL_STATE}, action) {
  switch (action.type) {
    case BOOKINGS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case BOOKINGS_LIST_SET_LIST:
      return {...state,bookings: action.value};
    case BOOKINGS_LIST_SET_SELECTED_BOOKING:
      return {...state, selectedBooking: action.value};
    case BOOKINGS_LIST_SET_CANCEL_DIALOG_OPEN:
      return {...state, cancelDialogOpen: action.value};
    case BOOKINGS_LIST_UPDATE_ITEM:
      const _bookings = [...state.bookings];
      for (let i = 0; i < _bookings.length; i++) {
        if (_bookings[i].id === state.selectedBooking.id) {
          _bookings.splice(i,1,state.selectedBooking);
          break;
        }
      }
      return {...state, bookings: [..._bookings]};
    default:
      return state;
  }
}
