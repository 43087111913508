import * as types from '../constants/actions';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import config from '../resources/js/config.js';
import fetch from 'isomorphic-fetch';
import async from 'async';
import crypto from 'crypto';

export function openForgotDialog() {
  return {
    type: types.LOGIN_OPEN_FORGOT_DIALOG,
    value: true
  };
}

export function closeForgotDialog() {
  return {
    type: types.LOGIN_CLOSE_FORGOT_DIALOG,
    value: false
  };
}

export function setEmail(value) {
  return {
    type: types.LOGIN_SET_EMAIL,
    value
  };
}

export function setPwd(value) {
  return {
    type: types.LOGIN_SET_PWD,
    value
  };
}

export function loginCleanData() {
  return {
    type: types.LOGIN_CLEAN_DATA
  };
}

export function loginSetData(value) {
  return {
    type: types.LOGIN_SET_DATA,
    value
  };
}

export function logout() {
  return function (dispatch) {
    dispatch(loginCleanData());
    localStorage.removeItem('data');
  }
}

export function validateLogin(history, nextRoute) {
  return function (dispatch, getState) {
    if (getState().login.token) {
      history.push(nextRoute);
    } else {
      dispatch(MessageActions.show('Tenes que ingresar al sistema con tu usuario o registrarte'));
    }
  }
}

export function loginGetData(history, originalPath = '') {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getToken = function(data, cb) {
      fetch(_uri + '/open/login',
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: getState().login.email,
            pwd: crypto.createHash('md5').update(getState().login.pwd).digest("hex")
          })
        }
      )
      .then(function(response) {
        if (!response.ok) {
          cb(new Error('No pudimos verificar tu identidad en el sistema'));
        } else {
          response.json()
          .then(data => {
            dispatch(loginSetData(data));
            localStorage.setItem('token', data.token);
            localStorage.setItem('data', JSON.stringify(data));
            cb(null,null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getToken
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (originalPath !== '') {
            console.log(getState().login)
            if (originalPath === '/admin-bookings' && getState().login.roleName !== 'USER') history.push('/admin-day-bookings');
            else history.push(originalPath)
          };
        }
    });
  }
}

export function loginRecoverPassword() {
  let _uri = config.server;

  return function (dispatch, getState) {

    const start = function(cb) {
      dispatch(closeForgotDialog());
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const recoverPassword = function(data, cb) {
      fetch(_uri + '/open/passwordrecovery',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: getState().login.email
          })
        }
      )
      .then(function(response) {
        if (!response.ok) {
          cb(new Error('Por favor comprueba que el email ingresado sea correcto'));
        } else {
          dispatch(MessageActions.show('Enviamos la clave temporal a tu email, por favor consulta tu casilla en unos minutos'));
          cb(null,null);
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      recoverPassword
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        }
    });
  }
}
