import * as types from '../constants/actions';
import fetch from 'isomorphic-fetch';
import async from 'async';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';

export function selectPet(item) {
  return { type: types.PET_SET_SELECTED, value: item };
}

export function getInitialData(history) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getSpecies = function(data, cbSpecies) {
      fetch(_uri + '/api/species',
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbSpecies(new Error(data.message));
            })
          } else {
            cbSpecies(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(species => {
            dispatch({type: types.PET_SET_SPECIES, values: species});
            cbSpecies(null,null);
          })
        }
      })
      .catch(err => {
        cbSpecies(err);
      });
    };

    const getPets = function(data, cbPets) {
      fetch(`${_uri}/api/users/${getState().login.userId}/pets`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbPets(new Error(data.message));
            })
          } else {
            cbPets(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(pets => {
            dispatch({type: types.PET_SET_PETS, values: pets});
            cbPets(null,null);
          })
        }
      })
      .catch(err => {
        cbPets(err);
      });
    };

    async.waterfall([
      start,
      getSpecies,
      getPets
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.PET_RESET});
          dispatch({type: types.PET_SET_FLIPPED, value: {form: 'newPet', flipped: getState().pet.pets.length === 0}});
          dispatch({type: types.PET_SET_USER_ID, value: getState().login.userId});
        }
    });
  }
}

export function setflipped(form, flipped) {
  return {
    type: types.PET_SET_FLIPPED,
    value: {form, flipped}
  }
}

export function setField(field, value) {
  let _action = null;
  switch (field) {
    case 'petName': _action = types.PET_SET_NAME; break;
    case 'petBirthDate': _action = types.PET_SET_BIRTHDATE; break;
    case 'petGender': _action = types.PET_SET_GENDER_ID; break;
    case 'SpecieId': _action = types.PET_SET_SPECIE_ID; break;
    case 'BreedId': _action = types.PET_SET_BREED_ID; break;
    default:
      _action = null;
  }
  return {
    type: _action,
    value
  }
}

export function getBreeds(specieID) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getBreeds = function(data, cb) {
      fetch(`${_uri}/api/species/${specieID}/breeds`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(breeds => {
            cb(null, breeds);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getBreeds
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.PET_SET_BREEDS, values: result.Breeds});
        }
    });
  }
}

export function setSpecieAndGetBreeds(value) {
  return function (dispatch, getState) {
    dispatch(setField('SpecieId', value));
    dispatch(setField('BreedId', ''));
    dispatch(getBreeds(value));
  }
}

export function validatePet(history, nextRoute) {
  return function (dispatch, getState) {
    if (getState().pet.petSelected) {
      history.push(nextRoute);
    } else {
      dispatch(MessageActions.show('Tenes que seleccionar una mascota para continuar'));
    }
  }
}

export function savePet(history, nextRoute = '') {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cbStart) {
      dispatch(OverlayActions.show());
      return cbStart(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const validate = function(data, cbValidate) {
      const _pet = getState().pet.pet;
      let _error = '';
      if (_pet.petName === null || _pet.petName === '') _error = 'Tenes que ingresar el nombre de tu mascota';
      else if (_pet.SpecieId === null || _pet.SpecieId === '' || _pet.SpecieId === 0) _error = 'Tenes que seleccionar la especie de tu mascota';
      else if (_pet.BreedId === null || _pet.BreedId === '' || _pet.BreedId === 0) _error = 'Tenes que seleccionar la raza de tu mascota';
      else if (_pet.petGender === null || _pet.petGender === '') _error = 'Tenes que seleccionar el género de tu mascota';
      return cbValidate(_error !== '' ? new Error(_error) : null, null);
    }

    const save = function(data, cbSave) {
      fetch(`${_uri}/api/pets`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(getState().pet.pet)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbSave(new Error(data.message));
            })
          } else {
            cbSave(new Error(response.statusText));
          }
        } else {
          return cbSave(null, null);
        }
      })
      .catch(err => {
        return cbSave(err);
      });
    }

    async.waterfall([
      start,
      validate,
      save
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (nextRoute === '') {
            dispatch(getInitialData());
          } else {
            history.push(nextRoute);
          }
        }
    });
  }
}
