import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import Container from './container';
import * as ReportActions from '../actions/reports';
import * as ContainerActions from '../actions/container';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
const PlotlyRenderers = createPlotlyRenderers(Plot);

class AdminReportsPage extends Component {
  render() {
    const _aggregators = require('react-pivottable/Utilities').aggregators;

    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Reportes</div>
          <PivotTableUI
              data={this.props.data && this.props.data.report_1? this.props.data.report_1:[]}
              rows={['Localidad']}
              cols={['Año','Mes','Dia']}
              vals={['#Cantidad Cumplida']}
              hiddenFromDragDrop={['#Cantidad Cumplida','#Cantidad Reservada',"#Cantidad Cancelada"]}
              hiddenFromAggregators={['Sexo','Especie', 'Raza','Localidad','Fecha']}
              aggregatorName={'Suma'}
              aggregators = {{
                "Suma":  _aggregators.Sum
              }}
              onChange={(s) => {this.setState(s)}}
              renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
              {...this.state}
          />
          <Fab key='refresh' size="small" color="secondary" aria-label="Refresh" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.Report.getReportData(this.props.history)}>
            <RefreshIcon />
          </Fab>

        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.petsList.height,
    roleName: state.login.roleName,
    data:state.reports.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      Report: bindActionCreators(ReportActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsPage);
