import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import * as AdminDayBookingsActions from '../actions/dayBooking';
import Grid from '@material-ui/core/Grid';
import Calendar from 'react-calendar'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import { VariableSizeList } from 'react-window';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';

import moment from 'moment';
import _ from 'underscore';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

class AdminDayBookings extends Component {
  componentDidMount() {
    this.props.actions.dayBooking.getInitialData();
  }

  render() {
    const _sortedBookings = _.sortBy(this.props.bookingList, x => x.bookingStartDate);
    const _sortedUsers = _.sortBy(this.props.userList, x => x.userName + x.userLastName);

    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Turnos</div>
          <Grid  container spacing={0} style={{display: 'flex', justifyContent: 'center', marginTop: 80}}>
            <div style={{height: 500, width: 719, borderRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto', fontSize: 32, flexDirection: 'row', padding: 40, color:'#456389'}}>
              <Grid  container spacing={0} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={7} style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <Calendar minDetail="month" locale="es-ES" calendarType="US" onClickDay={(value, event) => this.props.actions.dayBooking.getDataByDate(value)} value={moment.unix(this.props.bookingDisplayDate).toDate()} />
                </Grid>
                <Grid item xs={12} sm={5} style={{display: 'flex', justifyContent: 'center'}}>
                  <div className='bookingList'>
                    {
                       _sortedBookings.length === 0 ?
                        <div className='bookingListItem'>
                          <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', fontSize: 16}}>No hay turnos disponibles</div>
                        </div>
                      :
                         _sortedBookings.map((item, index) => {
                          let _style = '';
                          if (item.id) {
                            if (item.bookingStatus === 'D') _style = 'selectedDayDoneBookingListItem';
                            else if (item.bookingStatus === 'N') _style = 'selectedDayNotDoneBookingListItem';
                            else if (this.props.bookingSelected && item.bookingStartDate === this.props.bookingSelected.bookingStartDate) _style = 'selectedDayBookingTakenListItem';
                            else _style = 'dayBookingTakenListItem';
                          } else {
                            if (this.props.bookingSelected && item.bookingStartDate === this.props.bookingSelected.bookingStartDate) _style = 'selectedDayBookingListItem';
                            else _style = 'dayBookingListItem';
                          }
                          return (
                            <div key={`dayBooking_${index}`} className={_style} onClick={item.id ? () => { this.props.actions.dayBooking.selectBooking(item); this.props.actions.dayBooking.setActionDialogOpen(true); } : () => { this.props.actions.dayBooking.selectBooking(item); this.props.actions.dayBooking.setTakeDialogOpen(true); } }>
                              <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', fontSize: 16}}>{item.bookingStartTime}</div>
                              {
                                item.id ?
                                  <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', fontSize: 16}}>{item.serviceName}</div>
                                : null
                              }
                              {
                                item.id && item.Pet ?
                                  <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', fontSize: 16}}>{item.Pet.petName}</div>
                                : null
                              }
                              {
                                item.id && item.Booker ?
                                  <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', fontSize: 16}}>{`${item.Booker.userName}${item.Booker.userName ? (' ' + item.Booker.userLastName) : ''}`}</div>
                                : null
                              }
                            </div>
                          );
                        })
                    }
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
        <Dialog open={this.props.actionDialogOpen} onClose={()=> this.props.actions.dayBooking.setActionDialogOpen(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Gestión de turno</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ten en cuenta que si cancelas el turno, la cancelación será definitiva y liberará el mismo para que sea ocupado por otra mascota
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> this.props.actions.dayBooking.bookingUpdateStatus('C')} color="primary">
              Cancelar turno
            </Button>
            <Button onClick={()=> this.props.actions.dayBooking.bookingUpdateStatus('D')} color="primary">
              Cumplido
            </Button>
            <Button onClick={()=> this.props.actions.dayBooking.bookingUpdateStatus('N')} color="primary">
              No cumplido
            </Button>
            <Button onClick={()=> {this.props.actions.dayBooking.selectBooking(null); this.props.actions.dayBooking.setActionDialogOpen(false);}} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.props.takeDialogOpen} onClose={()=> this.props.actions.dayBooking.setTakeDialogOpen(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Reserva de turno</DialogTitle>
          <DialogContent>
            <Grid container spacing={0} style={{display: 'flex', justifyContent: 'center', width:340}}>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
                <Autocomplete
                  id="userAutocomplete"
                  fullWidth
                  disableListWrap
                  ListboxComponent={ListboxComponent}
                  options={_sortedUsers}
                  noOptionsText="No hay vecinos disponibles"
                  value={this.props.userSelected}
                  onChange={(event, value) => this.props.actions.dayBooking.setSelectedUser(value)}
                  getOptionLabel={(option) => `${option.userName}${option.userLastName ? ` ${option.userLastName}` : ''}${option.userIdNumber ? ` (${option.userIdNumber})` : ''}`}
                  renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true, }} label="Vecino" />}
                  renderOption={(option) => <Typography noWrap>{`${option.userName}${option.userLastName ? ` ${option.userLastName}` : ''}${option.userIdNumber ? ` (${option.userIdNumber})` : ''}`}</Typography>}
                />
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-start', marginTop:24}}>
                <Autocomplete
                  id="petAutocomplete"
                  fullWidth
                  disableListWrap
                  ListboxComponent={ListboxComponent}
                  noOptionsText="No hay mascotas disponibles"
                  value={this.props.petSelected}
                  options={this.props.userSelected && this.props.userSelected.Pets ? this.props.userSelected.Pets : []}
                  onChange={(event, value) => this.props.actions.dayBooking.setSelectedPet(value)}
                  getOptionLabel={(option) => option.petName}
                  renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true, }} label="Mascota" />}
                  renderOption={(option) => <Typography noWrap>{option.petName}</Typography>}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> this.props.actions.dayBooking.createBooking()} color="primary">
              Guardar
            </Button>
            <Button onClick={()=> {this.props.actions.dayBooking.setTakeDialogOpen(false);}} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookingDisplayDate: state.dayBooking.bookingDisplayDate,
    userList: state.dayBooking.userList,
    bookingList: state.dayBooking.bookingList,
    bookingSelected: state.dayBooking.bookingSelected,
    actionDialogOpen: state.dayBooking.actionDialogOpen,
    takeDialogOpen: state.dayBooking.takeDialogOpen,
    roleName: state.login.roleName,
    userSelected: state.dayBooking.userSelected,
    petSelected: state.dayBooking.petSelected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      dayBooking: bindActionCreators(AdminDayBookingsActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDayBookings);
