import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/home';
import Login from './components/login';
import BookingSelector from './components/bookingSelector';
import SignInUp from './components/signinup';
import Pets from './components/pets';
import Locks from './components/locks';
import Terms from './components/terms';
import Confirmation from './components/confirmation';
import MainContainer from './components/mainContainer';
import AdminPets from './components/admin-pets';
import AdminBookings from './components/admin-bookings';
import AdminDayBookings from './components/admin-day-bookings';
import EditPet from './components/admin-pets-editor';
import AdminStates from './components/admin-states';
import EditState from './components/admin-states-editor';
import AdminSpecies from './components/admin-species';
import EditSpecie from './components/admin-species-editor';
import AdminUsers from './components/admin-users';
import EditUser from './components/admin-users-editor';
import AdminCities from './components/admin-cities';
import EditCity from './components/admin-cities-editor';
import AdminBreeds from './components/admin-breeds';
import EditBreed from './components/admin-breeds-editor';
import AdminReports from './components/admin-reports';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AdminTermsAndConditions from './components/admin-termsAndConditions';
import * as LoginActions from './actions/login';
import moment from 'moment';
import 'moment/locale/es';

import './App.css';

moment.locale('es');

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        light: '#456389',
        main: '#122F55',
        dark: '#051C3B',
        contrastText: '#fff',
      },
      secondary: {
        light: '#D0F271',
        main: '#95c11e',
        dark: '#577600',
        contrastText: '#fff',
      },
    },
    status: {
      danger: 'orange',
    },
    typography: {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
      useNextVariants: true,
    },
    progress: {
      margin: 16,
    },
    close: {
      padding: 4,
    },
  }
);

const RedirectLoginHelper = (props) => {
  const {history} = props;
  const path = history.location.pathname;
  history.push(`/login`, {originalPath: path});
  return null;
}

class App extends Component {
  constructor() {
    super();
    this.loggedIn = function (nextState, replaceState) {
      if((localStorage !== null && localStorage.getItem('data') !== null))
      {
        let _data = JSON.parse(localStorage.getItem('data'));
        this.props.actions.login.loginSetData(_data);
        return true;
      }
      else
        return false;
    };

    this.routes =
      <Switch>
        <Route exact path="/" render={(props) => ( this.loggedIn() ? (<Home {...props} />) : (<Home {...props} />))} />
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Route path="/booking-selector" render={(props) => <BookingSelector {...props} />} />
        <Route path="/signinup" render={(props) => ( this.loggedIn() ? (<SignInUp {...props} />) : (<SignInUp {...props} />))} />
        <Route path="/pets" render={(props) => ( this.loggedIn() ? (<Pets {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/confirmation" render={(props) => ( this.loggedIn() ? (<Confirmation {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/locks" render={(props) => ( this.loggedIn() ? (<Locks {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/terms" render={(props) => ( this.loggedIn() ? (<Terms {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-pets-edit" render={(props) => ( this.loggedIn() ? (<EditPet {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-pets/:id" render={(props) => ( this.loggedIn() ? (<AdminPets {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-pets" render={(props) => ( this.loggedIn() ? (<AdminPets {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-bookings" render={(props) => ( this.loggedIn() ? (<AdminBookings {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-day-bookings" render={(props) => ( this.loggedIn() ? (<AdminDayBookings {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-states-edit" render={(props) => ( this.loggedIn() ? (<EditState {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-states" render={(props) => ( this.loggedIn() ? (<AdminStates {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-species-edit" render={(props) => ( this.loggedIn() ? (<EditSpecie {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-species" render={(props) => ( this.loggedIn() ? (<AdminSpecies {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-users-edit" render={(props) => ( this.loggedIn() ? (<EditUser {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-users" render={(props) => ( this.loggedIn() ? (<AdminUsers {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-users-edit/:id" render={(props) => ( this.loggedIn() ? (<EditUser {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-cities-edit" render={(props) => ( this.loggedIn() ? (<EditCity {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-cities" render={(props) => ( this.loggedIn() ? (<AdminCities {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-breeds-edit" render={(props) => ( this.loggedIn() ? (<EditBreed {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-breeds" render={(props) => ( this.loggedIn() ? (<AdminBreeds {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-reports" render={(props) => ( this.loggedIn() ? (<AdminReports {...props} />) : (<RedirectLoginHelper {...props} />))} />
        <Route path="/admin-termsAndConditions" render={(props) => ( this.loggedIn() ? (<AdminTermsAndConditions {...props} />) : (<RedirectLoginHelper {...props} />))} />
      </Switch>;
  }

  render() {
    return (
        <MuiThemeProvider theme={theme}>
          <MainContainer>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='es'>
              <BrowserRouter>
                {
                  this.routes
                }
              </BrowserRouter>
            </MuiPickersUtilsProvider>
          </MainContainer>
        </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.login.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(LoginActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
