import {
  DAY_BOOKING_RESET,
  DAY_BOOKING_SET_ID,
  DAY_BOOKING_SET_DATE,
  DAY_BOOKING_SET_TIME_RANGE,
  DAY_BOOKING_SET_BOOKING_LIST,
  DAY_BOOKING_SET_USER_LIST,
  DAY_BOOKING_SET_SELECTED,
  DAY_BOOKING_SET_SAVE_RESULT,
  DAY_BOOKING_SET_ACTION_DIALOG_OPEN,
  DAY_BOOKING_UPDATE_ITEM,
  DAY_BOOKING_SET_TAKE_DIALOG_OPEN,
  DAY_BOOKING_SET_SELECTED_USER,
  DAY_BOOKING_SET_SELECTED_PET
} from '../constants/actions';
import moment from 'moment';

const INITAL_STATE = {
  id: 0,
  bookingDisplayDate: moment(moment().format('YYYY-MM-DD 00:00:00')).unix(),
  bookingDate: moment.utc(moment().format('YYYY-MM-DD 00:00:00')).unix(),
  bookingStartTime: null,
  bookingEndTime: null,
  LocationId: 1,
  ServiceId: 1,
  OperatorUserId: 1,
  BookerUserId: null,
  PetId: null,
  bookingStartDate: null,
  bookingEndDate: null,
  bookingComment: '',
  bookingStatus: 'A',
  userList: [],
  bookingList: [],
  bookingSelected: null,
  bookingSaveResult: null,
  actionDialogOpen: false,
  takeDialogOpen: false,
  userSelected: null,
  petSelected: null
};

export default function overlay(state = {...INITAL_STATE}, action) {
  switch (action.type) {
  case DAY_BOOKING_RESET:
    return {...INITAL_STATE};
  case DAY_BOOKING_SET_ID:
    return {...state, id: action.value};
  case DAY_BOOKING_SET_DATE:
    const _bookingDisplayDate = moment(moment.unix(action.value).utc().format('YYYY-MM-DD HH:mm:ss') + moment().format('Z')).unix();
    return {...state, bookingDate: action.value, bookingDisplayDate: _bookingDisplayDate};
  case DAY_BOOKING_SET_TIME_RANGE:
    return {...state, bookingStartTime: action.start, bookingEndTime: action.end };
  case DAY_BOOKING_SET_BOOKING_LIST:
    return {...state, bookingList: action.values };
  case DAY_BOOKING_SET_USER_LIST:
    return {...state, userList: action.values };
  case DAY_BOOKING_SET_SELECTED:
    return {...state, bookingSelected: action.value};
  case DAY_BOOKING_SET_SELECTED_USER:
    return {...state, userSelected: action.value};
  case DAY_BOOKING_SET_SELECTED_PET:
    return {...state, petSelected: action.value};
  case DAY_BOOKING_SET_SAVE_RESULT:
    return {...state, bookingSaveResult: action.value};
  case DAY_BOOKING_SET_ACTION_DIALOG_OPEN:
    return {...state, actionDialogOpen: action.value};
  case DAY_BOOKING_SET_TAKE_DIALOG_OPEN:
    if (!action.value) {
      return {...state, takeDialogOpen: action.value, userSelected: null, petSelected: null };
    } else {
      return {...state, takeDialogOpen: action.value};
    }
  case DAY_BOOKING_UPDATE_ITEM:
    const _bookings = [...state.bookingList];
    for (let i = 0; i < _bookings.length; i++) {
      if (_bookings[i].id === state.selectedBooking.id) {
        _bookings.splice(i,1,state.selectedBooking);
        break;
      }
    }
    return {...state, bookingList: [..._bookings]};
  default:
    return state;
  }
}
