import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import fetch from 'isomorphic-fetch';
import async from 'async';

export function updateHeight(value) {
  return {
    type: types.BOOKINGS_LIST_SET_HEIGHT,
    value
  };
}

export function setBookingsList(values) {
  return {
    type: types.BOOKINGS_LIST_SET_LIST,
    value: values
  };
}

export function setSelectedBooking(value) {
  return {
    type: types.BOOKINGS_LIST_SET_SELECTED_BOOKING,
    value
  };
}

export function setCancelDialogOpen(value) {
  return {
    type: types.BOOKINGS_LIST_SET_CANCEL_DIALOG_OPEN,
    value
  };
}

export function selectBooking(value, history = null) {
  return function (dispatch, getState) {
    dispatch(setSelectedBooking(value));
    if (history) history.push(`/admin-bookings-edit`);
  }
}

export function getInitialData() {
  let _uri = config.server;

  return function (dispatch, getSpecie) {

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getBookings = function(data, cbBookings) {
      fetch(`${_uri}/api/bookings`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(result => {
              cbBookings(new Error(result.message));
            })
          } else {
            cbBookings(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(result => {
              cbBookings(null,result);
            })
          } else {
            cbBookings(null,[]);
          }
        }
      })
      .catch(err => {
        cbBookings(err);
      });
    };

    async.waterfall([
      start,
      getBookings
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(setBookingsList(result));
        }
    });
  }
}

const updateBooking = (booking) => {
  let _uri = config.server;
  return new Promise( function (resolve, reject) {
    fetch(`${_uri}/api/bookings/${booking.id}`,
      {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ` + localStorage.getItem('token'),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(booking)
      }
    )
    .then(function(response) {
      if (!response.ok) {
        if (response.status < 500) {
          response.json()
          .then(data => {
            reject(new Error(data.message));
          })
        } else {
          reject(new Error(response.statusText));
        }
      } else {
        resolve(response);
      }
    })
    .catch((err) => reject(err));
  });
}

export function bookingUpdateStatus(status) {
  return function (dispatch, getState) {
    let _booking = {...getState().bookingsList.selectedBooking, bookingStatus: status};

    console.log('selectedBooking',_booking);

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,_booking);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const update = function(data, cbBookings) {
      updateBooking(data)
      .then(function(response) {
        cbBookings(null,null);
      })
      .catch(err => {
        cbBookings(err);
      });
    };

    async.waterfall([
      start,
      update
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.BOOKINGS_LIST_SET_BOOKING_STATUS, value:{id:_booking.id, status}});
          if (getState().bookingsList.cancelDialogOpen) dispatch(setCancelDialogOpen(false));
          dispatch(getInitialData());
        }
    });
  }
}

export function bookingDeleteConfirmation(booking) {
  return function (dispatch, getState) {
    dispatch(selectBooking(booking));
    dispatch(setCancelDialogOpen(true));
  }
}

export function bookingDone(booking) {
  return function (dispatch, getState) {
    dispatch(bookingUpdateStatus(booking, 'D'));
  }
}
